<template>
  <h1 class="text-h4">
    <v-icon color="secondary" size="40">
      {{icon}}
    </v-icon>
    {{headline}}
    <span class="text-h6  grey--text">
        {{ entity }}
      </span>
  </h1>
</template>
<script>
/**
 * Snackbar to show user successful and not successful tries to edit, create or delete a value
 * @displayName Alert
 */
export default {
  name: "HeadlineDetailView",
  props: {
    icon: String,
    headline: String,
    entity: String,
  },
  methods:{
  }
}
</script>
